import React, { useState, useEffect } from 'react';
import '../PendingServiceRequest/PendingServiceRequestStyle.css';
import { apiCall } from '../../Utils/APIUtils/ApiUtils';
import CustomloaderSpinner from '../../DashboardComponents/LoaderSpinner/loader';
import WarningModal from '../../DashboardComponents/WarningModal/warningModal';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import Global from '../../Utils/Global';

const ActionButtons = ({ onAccept, onReject }) => (
    <div className="action-buttons">
        <button className="accept-button" onClick={onAccept}>Accept</button>
        <button className="reject-button" onClick={onReject}>Reject</button>
    </div>
);

const PendingServiceRequest = (props) => {

    const navigate = useNavigate();
    const [statusValue, setstatusValue] = useState("Nurse");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const [pendingProviderServiceListData, setpendingProviderServiceListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isAcceptButtonClicked, setisAcceptButtonClicked] = useState(false);
    const [isRejectButtonClicked, setisRejectButtonClicked] = useState(false);

    const dropDownStatusData = [
        {
            id: 0,
            title: "Nurse",
        },
        {
            id: 1,
            title: "Doctor",
        },
    ]

    const handleOptionClick = (title) => {
        setstatusValue(title);
        setIsDropdownVisible(false);
    };

    const downArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M5 7.5L10 12.5L15 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const openErrorModal = (message) => {
        setErrorMessage(message);
        setIsErrorModalOpen(true);
    };

    const handleLogOut = async () => {
        await signOut();
        localStorage.removeItem('authToken');
        navigate('/SignIn');
    }

    const closeErrorModal = async () => {
        setIsErrorModalOpen(false);
        setErrorMessage('');
        if (Global.authExpired) {
            await handleLogOut();
        }
    };

    const GetPendingProviderServicesList = async () => {
        try {
            const body = {
                "userType": statusValue == "Nurse" ? "3" : "4",
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            const response = await apiCall('admin/getpendingproviderserviceslist', body);
            setpendingProviderServiceListData(response.pendingservices);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e, "error");
            openErrorModal(e.msg || "Something went wrong, please try again");
        }
    };

    useEffect(() => {
        GetPendingProviderServicesList();
    }, [statusValue, isRejectButtonClicked, isAcceptButtonClicked]);

    const triggerAddProviderServices = async (item) => {
        try {
            const body = {
                "userId": item.userId.toString(),
                "providerTypeId": item.serviceId.toString(),
                "status": "1",
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            await apiCall('admin/addproviderservices', body);
            setisAcceptButtonClicked(!isAcceptButtonClicked);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e, "error");
            openErrorModal(e.msg || "Something went wrong, please try again");
        }
    };

    const triggerDeleteProviderServices = async (item) => {
        try {
            const body = {
                "userId": item.userId.toString(),
                "id": item.id.toString(),
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            await apiCall('admin/deleteproviderservices', body);
            setisRejectButtonClicked(!isRejectButtonClicked)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e, "error");
            openErrorModal(e.msg || "Something went wrong, please try again");
        }
    };

    return (
        <div className="pending-service-request">
            <div className="service-header">
                <div className='service-header-title-container'>
                    <h1>Pending Service Requests</h1>
                </div>
                <div className="Status_sort_container" onClick={() => setIsDropdownVisible(!isDropdownVisible)} >
                    <p>Status:</p>
                    <div className="dropdown" >
                        <button className="dropbtn">{statusValue}</button>
                    </div>
                    {isDropdownVisible && (
                        <div className="dropdown-content">
                            {dropDownStatusData.map((item) => (
                                <button className='dropdown-content-button' key={item.title} onClick={() => handleOptionClick(item.title)}>
                                    {item.title}
                                </button>
                            ))}
                        </div>
                    )}
                    <div className='downArrow' dangerouslySetInnerHTML={{ __html: downArrow }} />
                </div>
            </div>

            {pendingProviderServiceListData.length > 0 ? (
                <table className="pending-service-request-table">
                    <thead>
                        <tr>
                            <th>HOPE ID No.</th>
                            <th>provider Name</th>
                            <th>Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingProviderServiceListData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.hopeID}</td>
                                <td>{item.providerName}</td>
                                <td>{item.name}</td>
                                <td>
                                    <ActionButtons
                                        onAccept={() => triggerAddProviderServices(item)}
                                        onReject={() => triggerDeleteProviderServices(item)}
                                    />
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            ) : (
                <div className='no-pending-request'>
                    <p>No pending service requests</p>
                </div>
            )}
            {isLoading ? <CustomloaderSpinner /> : null}
            <WarningModal isOpen={isErrorModalOpen} errorMessage={errorMessage} onClose={closeErrorModal} />
        </div>
    );
};

export default PendingServiceRequest;
