import React, { useEffect, useState } from 'react';
import './mainDashboardStyle.css';
import profile_img from '../../Assets/WebSite_Assets/profile.png';
import DashboardInsideContent from '../DashboardContent/dashboard_content';
import NurseScreen from '../NurseRecords/NurseScreen';
import { useNavigate, useLocation } from 'react-router-dom';
import DoctorScreen from '../DoctorRecords';
import { signOut } from 'aws-amplify/auth';
import NutritionScreen from '../NutritionRecord/NutritionScreen';
import { apiCall } from '../../Utils/APIUtils/ApiUtils';
import CustomloaderSpinner from '../../DashboardComponents/LoaderSpinner/loader';
import WarningModal from '../WarningModal/warningModal';
import Global from '../../Utils/Global';
import PendingServiceRequest from '../../Components/PendingServiceRequest';
// import NoShowAppointment from '../../Components/NoShowAppointment';
import DomestiCaretaker from '../DomesticRecord/DomesticScreen';

const MainDashboard = () => {
    const location = useLocation();
    let response = JSON.parse(sessionStorage.getItem('userData'));
    const [dashboardData, setdashboardData] = useState([]);
    const [nurseData, setNurseData] = useState({});
    const [doctorData, setDoctorData] = useState({});
    const [nutritionistData, setNutritionistData] = useState({});
    const [domesticCareTaker, setDomesticCareTaker] = useState({});
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [pendingTaskModal, setpendingTaskModal] = useState(false);
    const storedData = sessionStorage.getItem('userData');
    const [dynamicStatusValue, setdynamicStatusValue] = useState("Active");
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openErrorModal = (message) => {
        setErrorMessage(message);
        setIsErrorModalOpen(true);
    };

    const closeErrorModal = async () => {
        setIsErrorModalOpen(false);
        setErrorMessage('');
        if (Global.authExpired) {
            await handleLogOut();
        }
    };

    const downArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M5 7.5L10 12.5L15 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const rightArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M3.75 10.625L6.875 7.5L3.75 4.375M8.125 10.625L11.25 7.5L8.125 4.375" stroke="#C3C1CA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const searchIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M22.75 22.75L18.9584 18.9583M21.6667 12.4583C21.6667 17.544 17.544 21.6667 12.4583 21.6667C7.37271 21.6667 3.25 17.544 3.25 12.4583C3.25 7.37271 7.37271 3.25 12.4583 3.25C17.544 3.25 21.6667 7.37271 21.6667 12.4583Z" stroke="#86848E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const bellIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M8.57465 19.2502C9.22102 19.8207 10.0701 20.1668 11 20.1668C11.9299 20.1668 12.779 19.8207 13.4253 19.2502M2.10272 5.33506C2.08957 4.00466 2.80684 2.7623 3.96558 2.10849M19.8936 5.33507C19.9068 4.00467 19.1895 2.76231 18.0307 2.1085M16.5 7.3335C16.5 5.87481 15.9205 4.47586 14.8891 3.44441C13.8576 2.41296 12.4587 1.8335 11 1.8335C9.54129 1.8335 8.14235 2.41296 7.1109 3.44441C6.07945 4.47586 5.49998 5.87481 5.49998 7.3335C5.49998 10.1662 4.78542 12.1056 3.98718 13.3885C3.31385 14.4705 2.97719 15.0116 2.98953 15.1625C3.0032 15.3296 3.03861 15.3934 3.17328 15.4933C3.2949 15.5835 3.84319 15.5835 4.93977 15.5835H17.0602C18.1568 15.5835 18.7051 15.5835 18.8267 15.4933C18.9614 15.3934 18.9968 15.3296 19.0104 15.1625C19.0228 15.0116 18.6861 14.4705 18.0128 13.3885C17.2146 12.1056 16.5 10.1662 16.5 7.3335Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const dashboardicon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7 2.5H3.83333C3.36662 2.5 3.13327 2.5 2.95501 2.59083C2.79821 2.67072 2.67072 2.79821 2.59083 2.95501C2.5 3.13327 2.5 3.36662 2.5 3.83333V7C2.5 7.46671 2.5 7.70007 2.59083 7.87833C2.67072 8.03513 2.79821 8.16261 2.95501 8.24251C3.13327 8.33333 3.36662 8.33333 3.83333 8.33333H7C7.46671 8.33333 7.70007 8.33333 7.87833 8.24251C8.03513 8.16261 8.16261 8.03513 8.24251 7.87833C8.33333 7.70007 8.33333 7.46671 8.33333 7V3.83333C8.33333 3.36662 8.33333 3.13327 8.24251 2.95501C8.16261 2.79821 8.03513 2.67072 7.87833 2.59083C7.70007 2.5 7.46671 2.5 7 2.5Z" stroke="#29282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1667 2.5H13C12.5333 2.5 12.2999 2.5 12.1217 2.59083C11.9649 2.67072 11.8374 2.79821 11.7575 2.95501C11.6667 3.13327 11.6667 3.36662 11.6667 3.83333V7C11.6667 7.46671 11.6667 7.70007 11.7575 7.87833C11.8374 8.03513 11.9649 8.16261 12.1217 8.24251C12.2999 8.33333 12.5333 8.33333 13 8.33333H16.1667C16.6334 8.33333 16.8667 8.33333 17.045 8.24251C17.2018 8.16261 17.3293 8.03513 17.4092 7.87833C17.5 7.70007 17.5 7.46671 17.5 7V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5Z" stroke="#29282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1667 11.6667H13C12.5333 11.6667 12.2999 11.6667 12.1217 11.7575C11.9649 11.8374 11.8374 11.9649 11.7575 12.1217C11.6667 12.2999 11.6667 12.5333 11.6667 13V16.1667C11.6667 16.6334 11.6667 16.8667 11.7575 17.045C11.8374 17.2018 11.9649 17.3293 12.1217 17.4092C12.2999 17.5 12.5333 17.5 13 17.5H16.1667C16.6334 17.5 16.8667 17.5 17.045 17.4092C17.2018 17.3293 17.3293 17.2018 17.4092 17.045C17.5 16.8667 17.5 16.6334 17.5 16.1667V13C17.5 12.5333 17.5 12.2999 17.4092 12.1217C17.3293 11.9649 17.2018 11.8374 17.045 11.7575C16.8667 11.6667 16.6334 11.6667 16.1667 11.6667Z" stroke="#29282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 11.6667H3.83333C3.36662 11.6667 3.13327 11.6667 2.95501 11.7575C2.79821 11.8374 2.67072 11.9649 2.59083 12.1217C2.5 12.2999 2.5 12.5333 2.5 13V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H7C7.46671 17.5 7.70007 17.5 7.87833 17.4092C8.03513 17.3293 8.16261 17.2018 8.24251 17.045C8.33333 16.8667 8.33333 16.6334 8.33333 16.1667V13C8.33333 12.5333 8.33333 12.2999 8.24251 12.1217C8.16261 11.9649 8.03513 11.8374 7.87833 11.7575C7.70007 11.6667 7.46671 11.6667 7 11.6667Z" stroke="#29282F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

    const exiticon = `<svg width="35" height="35" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.25 40.68C42.9185 40.68 42.6005 40.8117 42.3661 41.0461C42.1317 41.2805 42 41.5985 42 41.93V58.75H5.25V5.25H42V22.07C42 22.4015 42.1317 22.7195 42.3661 22.9539C42.6005 23.1883 42.9185 23.32 43.25 23.32C43.5815 23.32 43.8995 23.1883 44.1339 22.9539C44.3683 22.7195 44.5 22.4015 44.5 22.07V4C44.5 3.66848 44.3683 3.35054 44.1339 3.11612C43.8995 2.8817 43.5815 2.75 43.25 2.75H4C3.66848 2.75 3.35054 2.8817 3.11612 3.11612C2.8817 3.35054 2.75 3.66848 2.75 4V60C2.75 60.3315 2.8817 60.6495 3.11612 60.8839C3.35054 61.1183 3.66848 61.25 4 61.25H43.25C43.5815 61.25 43.8995 61.1183 44.1339 60.8839C44.3683 60.6495 44.5 60.3315 44.5 60V41.93C44.5 41.5985 44.3683 41.2805 44.1339 41.0461C43.8995 40.8117 43.5815 40.68 43.25 40.68Z" fill="black"/>
    <path d="M60.63 30.9199L48 23.6299C47.7544 23.4859 47.467 23.43 47.1853 23.4715C46.9037 23.513 46.6446 23.6494 46.451 23.8582C46.2574 24.0669 46.1409 24.3355 46.1207 24.6195C46.1005 24.9035 46.1779 25.1859 46.34 25.4199L50 30.7499H18.63C18.2985 30.7499 17.9805 30.8816 17.7461 31.1161C17.5117 31.3505 17.38 31.6684 17.38 31.9999C17.38 32.3315 17.5117 32.6494 17.7461 32.8838C17.9805 33.1183 18.2985 33.2499 18.63 33.2499H50L46.34 38.58C46.1885 38.8151 46.1195 39.0941 46.144 39.3728C46.1685 39.6515 46.2851 39.9141 46.4753 40.1192C46.6655 40.3243 46.9186 40.4603 47.1947 40.5057C47.4708 40.5511 47.7541 40.5034 48 40.3699L60.63 33.08C60.8164 32.968 60.9706 32.8096 61.0777 32.6204C61.1847 32.4311 61.241 32.2174 61.241 31.9999C61.241 31.7825 61.1847 31.5688 61.0777 31.3795C60.9706 31.1903 60.8164 31.0319 60.63 30.9199V30.9199Z" fill="black"/>
    </svg>
  `;


    const sideBarData = [
        {
            id: 1,
            label: "Hope Nurses",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
            rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
        },
        {
            id: 2,
            label: "Hope Doctors",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
            rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
        },
        {
            id: 3,
            label: "Hope Nutritionist",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
            rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
        },
        {
            id: 4,
            label: "Hope Domestic Caretaker",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
            rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
        },
        {
            id: 5,
            label: "Pending Service Requests",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
            rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
        },
        // {
        //     id: 6,
        //     label: "No Show Appointment",
        //     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        //             <path d="M10.8337 5.83333L9.90404 3.9741C9.6365 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333ZM7.50033 11.6667H12.5003" stroke="#86848E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        //         </svg>`,
        //     rightArrowIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        // <path d="M7.5 15L12.5 10L7.5 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        // </svg>`
        // },
    ]

    const handleLogOut = async () => {
        await signOut();
        localStorage.removeItem('authToken');
        navigate('/SignIn');
    }

    const handleActiveItemChange = (item) => {
        setActiveItem(item);
    };

    const handleStatusvalue = (item) => {
        setdynamicStatusValue(item);
    }

    const getAdminDashboardData = async () => {
        try {
            const body = {
                "coord": [
                    "24.623061",
                    "10.830960"
                ],
                "location": "Mumbai",
                "deviceInfo": "android"
            }
            setIsLoading(true);
            const response = await apiCall('admin/getadmindashboarddata', body);
            setdashboardData(response);
            for (const data of response) {
                if (data.userType == "Nurse") {
                    setNurseData(data);
                } else if (data.userType == "Nutrition") {
                    setNutritionistData(data);
                } else if (data.userType == "Doctor") {
                    setDoctorData(data);
                } else {
                    setDomesticCareTaker(data);
                }
            }
            Global.isDashboardDataUpdated = false;
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e, "error");
            openErrorModal(e.msg || "Something went wrong, please try again");
        }
    };

    useEffect(() => {
        getAdminDashboardData();
    }, [activeItem])

    return (
        <div className="main-dashboard-contianer">
            <div className='dashboard-container'>
                <div className="border_below_profile">
                </div>
                <div className="right-arrow-icon">
                    <div className='rightArrow' dangerouslySetInnerHTML={{ __html: rightArrow }} />
                </div>
                <div className="border-sidebar"></div>
                <div className="sidebar">
                    <div className="left_header_container">
                        <div className="img_container">
                            <img src={profile_img} alt="Profile Picture" />
                        </div>
                        <div className="name_profession_container">
                            <h1 className='profile_name'>{response.firstName} {response.lastName} </h1>
                        </div>
                        {/* <div className='downArrow' dangerouslySetInnerHTML={{ __html: downArrow }} /> */}
                    </div>
                    <div className="sidebar_navbar_container">
                        <div className="title_dashboard" onClick={() => setActiveItem(0)}>
                            <div className='dashboardicon' dangerouslySetInnerHTML={{ __html: dashboardicon }} />
                            <p className={`${activeItem === 0 ? 'dashboard-active' : 'dashboard-Inactive'}`}>Dashboard</p>
                        </div>
                        <ul>
                            {sideBarData.map((item) => (
                                <li key={item.id} onClick={() => setActiveItem(item.id)}>
                                    <div className="sidebar_Containts" >
                                        <div className='' dangerouslySetInnerHTML={{ __html: item.icon }} />
                                        <p className={`${activeItem === item.id ? 'item-active' : 'item-Inactive'}`}>{item.label}</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: item.rightArrowIcon }} />
                                </li>
                            )
                            )}
                        </ul>
                    </div>
                    <div className="right_header_container"></div>
                </div>
                <div className="header">
                    <div className="top_header_container">
                        <div className="search_container">
                            <div className="search_icon">
                                <div className='searchIcon' dangerouslySetInnerHTML={{ __html: searchIcon }} />
                            </div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    id="text-input"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)} />
                            </div>
                        </div>
                        <div className="seperater_container"></div>
                        {/* <div className="bell_icon_container" onClick={() => setpendingTaskModal(!pendingTaskModal)}>
                            <div className='bellIcon' dangerouslySetInnerHTML={{ __html: bellIcon }} />
                        </div> */}
                        {
                            pendingTaskModal ?
                                <div className="pending_Task_Modal_Container" onClick={() => setpendingTaskModal(false)}>
                                    <div className={`${activeItem === 0 ? "dashboard_active_arrow_element" : "down_Arrow_Element"}`}></div>
                                    <div className="pending_Task_Modal_Content">
                                    </div>

                                </div> : null
                        }
                        <div onClick={() => handleLogOut()} style={{ marginLeft: 25 }} className='exitIcon' dangerouslySetInnerHTML={{ __html: exiticon }} />
                    </div>
                    <div className="main-content">
                        {activeItem === 0 && <DashboardInsideContent nurseData={nurseData} domesticCareTaker={domesticCareTaker} doctorData={doctorData} nutritionistData={nutritionistData} onItemSelect={handleActiveItemChange} statusHandle={handleStatusvalue} />}
                        {activeItem === 1 && <NurseScreen searchQuery={searchQuery} StatusValueData={dynamicStatusValue} />}
                        {activeItem === 2 && <DoctorScreen searchQuery={searchQuery} StatusValueData={dynamicStatusValue} />}
                        {activeItem === 3 && <NutritionScreen searchQuery={searchQuery} StatusValueData={dynamicStatusValue} />}
                        {activeItem === 4 && <DomestiCaretaker searchQuery={searchQuery} StatusValueData={dynamicStatusValue} />}
                        {activeItem === 5 && <PendingServiceRequest />}
                        {/* {activeItem === 6 && <NoShowAppointment />} */}
                    </div>
                </div>
            </div>
            {isLoading ? <CustomloaderSpinner /> : null}
            <WarningModal isOpen={isErrorModalOpen} errorMessage={errorMessage} onClose={closeErrorModal} />
        </div>
    );
}

export default MainDashboard;
