import React from 'react'
import './dashboard_content_style.css'

const Dashboard_content = (props) => {
    const storedData = sessionStorage.getItem('userData');
    let passednurseData = props?.nurseData;
    let passeddoctorData = props.doctorData;
    let passedNutritionistData = props?.nutritionistData;  // change to NutritionistData
    let passedDomesticCaretakerData = props?.domesticCareTaker;  // change to NutritionistData

    const handleNurseClick = (isInnerDataclicked, title) => {
        if (isInnerDataclicked && title === "Total") {
            props.onItemSelect(0);
        } else {
            if (isInnerDataclicked) {
                props.statusHandle(title);
            }
            props.onItemSelect(1);
        }
    };

    const handleDoctorClick = (isInnerDataclicked, title) => {
        if (isInnerDataclicked && title === "Total") {
            props.onItemSelect(0);
        } else {
            if (isInnerDataclicked) {
                props.statusHandle(title);
            }
            props.onItemSelect(2);
        }
    };

    const handleNutritionistClick = (isInnerDataclicked, title) => {
        if (isInnerDataclicked && title === "Total") {
            props.onItemSelect(0);
        } else {
            if (isInnerDataclicked) {
                props.statusHandle(title);
            }
            props.onItemSelect(3);
        }
    };

    const handleDomesticCaretakerClick = (isInnerDataclicked, title) => {
        if (isInnerDataclicked && title === "Total") {
            props.onItemSelect(0);
        } else {
            if (isInnerDataclicked) {
                props.statusHandle(title);
            }
            props.onItemSelect(4);
        }
    };

    const dashboard_table_headers = [
        {
            id: 0,
            title: "Type",
        },
        {
            id: 1,
            title: "Pending",
        }, {
            id: 2,
            title: "Correction",

        }, {
            id: 3,
            title: "Blocked",
        }, {
            id: 4,
            title: "Active",
        },
        {
            id: 5,
            title: "Resent",
        }, {
            id: 6,
            title: "Terminated",
        }, {
            id: 7,
            title: "Rejected",
        }, {
            id: 8,
            title: "Total",
        }
    ]

    const nurse_Data = [
        { id: 0, title: "Pending", TotalNumber: passednurseData?.pending },
        { id: 1, title: "Pending with correction", TotalNumber: passednurseData?.correction },
        { id: 2, title: "Blocked", TotalNumber: passednurseData?.blocked },
        { id: 3, title: "Active", TotalNumber: passednurseData?.active },
        { id: 4, title: "Resent", TotalNumber: passednurseData?.resent },
        { id: 5, title: "Terminated", TotalNumber: passednurseData?.terminated },
        { id: 6, title: "Rejected", TotalNumber: passednurseData?.rejected },
        { id: 7, title: "Total", TotalNumber: passednurseData?.total }
    ];

    const doctor_Data = [
        { id: 0, title: "Pending", TotalNumber: passeddoctorData?.pending },
        { id: 1, title: "Pending with correction", TotalNumber: passeddoctorData?.correction },
        { id: 2, title: "Blocked", TotalNumber: passeddoctorData?.blocked },
        { id: 3, title: "Active", TotalNumber: passeddoctorData?.active },
        { id: 4, title: "Resent", TotalNumber: passeddoctorData?.resent },
        { id: 5, title: "Terminated", TotalNumber: passeddoctorData?.terminated },
        { id: 6, title: "Rejected", TotalNumber: passeddoctorData?.rejected },
        { id: 7, title: "Total", TotalNumber: passeddoctorData?.total }
    ];

    const nutritionist_Data = [
        { id: 0, title: "Pending", TotalNumber: passedNutritionistData?.pending },
        { id: 1, title: "Pending with correction", TotalNumber: passedNutritionistData?.correction },
        { id: 2, title: "Blocked", TotalNumber: passedNutritionistData?.blocked },
        { id: 3, title: "Active", TotalNumber: passedNutritionistData?.active },
        { id: 4, title: "Resent", TotalNumber: passedNutritionistData?.resent },
        { id: 5, title: "Terminated", TotalNumber: passedNutritionistData?.terminated },
        { id: 6, title: "Rejected", TotalNumber: passedNutritionistData?.rejected },
        { id: 7, title: "Total", TotalNumber: passedNutritionistData?.total }
    ];

    const domesticCareTaker_Data = [
        { id: 0, title: "Pending", TotalNumber: passedDomesticCaretakerData?.pending },
        { id: 1, title: "Pending with correction", TotalNumber: passedDomesticCaretakerData?.correction },
        { id: 2, title: "Blocked", TotalNumber: passedDomesticCaretakerData?.blocked },
        { id: 3, title: "Active", TotalNumber: passedDomesticCaretakerData?.active },
        { id: 4, title: "Resent", TotalNumber: passedDomesticCaretakerData?.resent },
        { id: 5, title: "Terminated", TotalNumber: passedDomesticCaretakerData?.terminated },
        { id: 6, title: "Rejected", TotalNumber: passedDomesticCaretakerData?.rejected },
        { id: 7, title: "Total", TotalNumber: passedDomesticCaretakerData?.total }
    ];

    return (
        <div className="Dashboard_content_container">
            <div className="main-content-header">
                <h1>Dashboard</h1>
            </div>
            <table>
                <thead>
                    <tr>
                        {dashboard_table_headers.map((item) => (
                            <th key={item.id}>{item.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <button className='type-common-class'>
                                Nurse
                            </button>
                        </td>
                        {nurse_Data.map((item) => (
                            <td key={item.id}>
                                <button className='button-common-class' style={item.title == "Total" ? { cursor: "default" } : { cursor: 'pointer' }} onClick={() => handleNurseClick(true, item.title)} >
                                    {item.TotalNumber}
                                </button>
                            </td>
                        )
                        )}
                    </tr>
                    <tr>
                        <td>
                            <button className='type-common-class'>
                                Doctor
                            </button>
                        </td>
                        {doctor_Data.map((item) => (
                            <td key={item.id}>
                                <button className='button-common-class' style={item.title == "Total" ? { cursor: "default" } : { cursor: 'pointer' }} onClick={() => handleDoctorClick(true, item.title)} >
                                    {item.TotalNumber}
                                </button>
                            </td>
                        )
                        )}
                    </tr>
                    <tr>
                        <td>
                            <button className='type-common-class'>
                                Nutritionist
                            </button>
                        </td>
                        {nutritionist_Data.map((item) => (
                            <td key={item.id}>
                                <button className='button-common-class' style={item.title == "Total" ? { cursor: "default" } : { cursor: 'pointer' }} onClick={() => handleNutritionistClick(true, item.title)} >
                                    {item.TotalNumber}
                                </button>
                            </td>
                        )
                        )}
                    </tr>
                    <tr>
                        <td>
                            <button className='type-common-class'>
                                Domestic Caretaker
                            </button>
                        </td>
                        {domesticCareTaker_Data.map((item) => (
                            <td key={item.id}>
                                <button className='button-common-class' style={item.title == "Total" ? { cursor: "default" } : { cursor: 'pointer' }} onClick={() => handleDomesticCaretakerClick(true, item.title)} >
                                    {item.TotalNumber}
                                </button>
                            </td>
                        )
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Dashboard_content;
